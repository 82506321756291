///// FLEX POWER ////

.flex (@fd: row, @align: center, @js: space-between) {
    display: flex;
    flex-direction: @fd;
    align-items: @align;
    justify-content: @js;
}

.flex-i (@fd: row, @align: center, @js: space-between) {
    display: inline-flex;
    flex-direction: @fd;
    align-items: @align;
    justify-content: @js;
}

// FLEX SHORT
.flex-center(@direction: row) {
    display: flex;
    flex-direction: @direction;
    align-items: center;
    justify-content: center;
}

.flex-stretch(@direction: row) {
    display: flex;
    flex-direction: @direction;
    align-items: stretch;
    justify-content: space-between;
}

// GRID SYSTEM
.grid (@row: 1fr, @col: 1fr 1fr 1fr, @gap:20px ) {
    display: grid;
    grid-template-rows: @row;
    grid-template-columns: @col;
    grid-gap: @gap;
}

.grid-ar (@row: auto, @col: 1fr 1fr 1fr, @gap:20px) {
    display: grid;
    grid-auto-rows: @row;
    grid-template-columns: @col;
    grid-gap: @gap;
}

.grow (@start: 1, @end: -1) {
    grid-row: @start @end;
}

.gcol (@start: 1, @end: -1) {
    grid-column: @start @end;
}

.garea (@rstart: 1, @rend: -1, @cstart: 1, @cend: -1) {
    grid-row: @rstart @rend;
    grid-column: @cstart @cend;
}

// BTN COLOR
.btn-color (@colorbg: #ddd, @colortxt: #fff) {
    border-color: @colorbg;
    background-color: @colorbg;
    color: @colortxt;

    &:hover {
        background-color: transparent;
        color: @colorbg;
    }
}

.btn-color-invert (@colorbg: #ddd, @colortxt: #fff) {
    color: @colorbg;
    border-color: @colorbg;
    background-color: transparent;

    &:hover {
        color: @colortxt;
        background-color: @colorbg;
    }
}

.btn-gradient (@cstart: #eee, @center: #333, @cend: #000, @ctxt: #fff) {
    color: @ctxt;
    background: linear-gradient(90deg, @cstart, @center 50%, @cend) ~"var(--x, 0) / 150%";

    &:hover {
        --x: 100%;
    }
}

/////////////// CLASSES CSS3 PRÉCONSTRUITES ////////////

/// Coins arrondis
.rounded (@radius: 5px) {
    border-radius: @radius;
}

/// Box shadow inset
.box-shadow-inset (@x: 10px, @y: 10px, @blur: 40px, @spread: 0, @color: 0.75) {
    box-shadow: @x @y @blur @spread rgba(0, 0, 0, @color) inset;
}

/// Box shadow
.box-shadow (@x: 0px, @y: 3px, @blur: 5px, @opacity: 0.5) {
    box-shadow: @x @y @blur rgba(0, 0, 0, @opacity);
}

/// Text shadow
.txt-shadow (@x: 1px, @y: 1px, @blur: 1px, @opacity: 0.1) {
    text-shadow: @x @y @blur rgba(0, 0, 0, @opacity);
}

/// Transition
.transition (@prop: all, @time: 250ms, @ease: ease) {
    transition: @prop @time @ease;
}

/// Transform rotate
.rotate (@rotate: 180deg) {
    transform: rotate(@rotate);
}

/// Transform translate
.translate (@y: 20px, @x: 10px) {
    transform: translateY(@y) translateX(@x);
}

/// Transform translateX
.translateX (@x: 10px) {
    transform: translateX(@x);
}

/// Transform translateY
.translateY (@y: 20px) {
    transform: translateY(@y);
}

/// Transform scale
.scale (@val: 1.4) {
    transform: scale(@val);
}

/// Transform origin
.origin (@x: 0, @y: 0) {
    transform-origin: @x @y;
}

/// Transform
.transform (@rotate: 90deg, @scale: 1, @skew: 1deg, @translate: 10px) {
    transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
}

/// Gradient
.gradient (@origin: bottom, @color1: #fff, @color2: #000) {
    background-image: linear-gradient(to @origin, @color1, @color2);
}

/// Column
.column (@count: 3, @width: 290px, @gap: 42px) {
    columns: @width @count;
    column-gap: @gap;

    & > * {
        break-inside: avoid;
    }
}

// FORMS
.placeholder(@opacity : 0.3) {
    /* stylelint-disable-next-line selector-no-vendor-prefix */
    &::-webkit-input-placeholder {
        opacity: @opacity;
    }

    /* stylelint-disable-next-line selector-no-vendor-prefix */
    &::-moz-placeholder {
        opacity: @opacity;
    }

    /* stylelint-disable-next-line selector-no-vendor-prefix */
    &:-ms-input-placeholder {
        opacity: @opacity;
    }
}

// NO SCROLLBAR
.no-scrollbar(@x: auto, @y: hidden) {
    overflow: @x @y;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
