// COMMONS

.btn {
    margin: auto;
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0 20px;
    min-height: @height-item;
    height: @height-item;
    text-align: center;
    padding-top: 4px; // fix line-height typo
    border: 2px solid transparent;
    .flex-i(row,center,center);
    .rounded(@rounded-item);
    .transition;

    @media print {
        display: none;
    }

    span {
        margin-right: 10px;
    }
} // .btn

.btn-primary {
    .btn-color(@color, #fff);

    &.invert {
        .btn-color-invert(@color, #fff);
    }

    &.variant {
        .btn-color(@color-s, #fff);
    }
}

.btn-secondary {
    .btn-color(@color2, #fff);

    &.invert {
        .btn-color-invert(@color2, #fff);
    }
}

.btn-tertiary {
    .btn-color(@color2, #fff);

    &.invert {
        .btn-color-invert(@color2, #fff);
    }
}

// HEADINGS
.h1 {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 40px;
}

.h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.1;
}

.h3 {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.1;
}

.h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.1;
}
