// PAGES

.ck {
    padding: 80px 20px;

    h1 {
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 40px;
    }

    h2 {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.1;
        padding-top: 15px;
        margin-bottom: 25px;    
    }

    h3 {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.1;
        padding-top: 15px;
        margin-bottom: 15px;
    }

    h4 {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 10px;
    }
}
