//// HOME PAGE //
:root {
    --item-icon-w: 140px;
    --item-icon-wi: 120px;
    --item-icon-size: 8rem;
    --og-width: 1175px;

    @media @desktop {
        //--og-width: 100%;
    }
}

.nav-home {
    position: fixed;
    top: 15vh;
    right: 5vw;
    z-index: 1000;
    .flex(column,center,flex-start);

    @media @laptop {
        display: none;
    }

    .bubble {
        display: flex;
        min-width: 18px;
        min-height: 18px;
        width: 18px;
        height: 18px;
        border: 1px solid @color;
        margin-bottom: 30px;
        .rounded(50%);

        &.active {
            background-color: @color;
        }
    }
}

.home-top {
    min-height: calc(100vh - 60px);
    padding: 10px;
    background: #fff url('../img/header.jpg') center top no-repeat;
    background-size: cover;
    .flex-center;

    @media @mobile {
        min-height: calc(100vh - 150px);
    }

    .logo {
        width: 80%;
        max-width: 800px;
    }

    .btn {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 900;
    }
} // .home-top

.vision-offre {
    padding-top: 180px;
    margin-bottom: 90px;
    .grid(1fr,repeat(2,1fr),40px);

    @media @desktop {
        grid-template-columns: 1fr;
    }

    @media @tablet {
        padding-top: 80px;
    }

    img {
        max-width: 100%;
    }
} // .vision-offre

.vision {
    position: relative;
    padding-left: 80px;
    
    @media @desktop {
        padding: 0 70px;
    }

    @media @mobile {
        padding: 0;
    }

    &::after {
        .picto-font;

        content: @icon-quote;
        color: @color2;
        font-size: 4rem;
        display: inline-flex;
        position: absolute;
        right: 40px;
        bottom: 40px;

        @media @desktop-wide {
            right: -60px;
            bottom: 0;
        }

        @media @desktop {
            right: 0;
        }

        @media @mobile {
            display: none;
        }
    }

    .h1 {
        position: relative;

        @media @mobile {
            padding-left: 70px;
        }

        &::before {
            .picto-font;

            content: @icon-quote;
            color: @color2;
            font-size: 5rem;
            display: inline-flex;
            position: absolute;
            left: -70px;
            top: 0;
            .rotate(-180deg);

            @media @mobile {
                left: 0;
            }
        }
    }
} // .vision

.offre {
    @media @desktop {
        max-width: none;
    }

    .h1 {
        color: @color;
        padding: 0 30px;
        margin-bottom: 50px;
    }
}

.illu-og {
    @media @desktop {
        .no-scrollbar;
    }
}

.items-offre-globale {
    max-width: var(--og-width);
    min-height: 400px;
    margin: 0 auto 70px auto;
    background: transparent url('../img/offre-globale.jpg') center 15px no-repeat;
    .grid(1fr,repeat(5, 1fr),0);

    @media @desktop {
        width: var(--og-width);     
    }

    li {
        text-align: center;

        &:nth-child(odd) {
            align-self: flex-end;
        }
    }
}

.qualite {
    background-color: @color2-s;
    padding: 120px 0;
    color: #fff;

    .h1 {
        text-align: center;
        margin-bottom: 100px;
    }
}

.items-qualite {
    margin-bottom: 40px;
    .grid(1fr,repeat(4,1fr,),20px);

    @media @desktop {
        grid-template-columns: repeat(2,1fr);
    }

    .item-icon {
        .flex(column,center,center);

        span {
            text-align: center;
            .h3;
        }
    }

    
} // .items-qualite

.item-icon {
    i {
        position: relative;
        min-width: var(--item-icon-w);
        max-width: var(--item-icon-w);
        height: var(--item-icon-w);
        background: @color;
        background: linear-gradient(270deg,@color2, @color-m);
        margin-bottom: 30px;
        overflow: hidden;
        .rounded(50%);
        .flex-center;

        &::after {
            background-color: #fff;
            border-radius: 50%;
            content: "";
            height: var(--item-icon-wi);
            position: absolute;
            width: var(--item-icon-wi);
            z-index: 5;
        }

        &::before {
            position: relative;
            z-index: 6;
            color: @color;
            font-size: var(--item-icon-size);
        }
    }
}

.outils-modernes {
    --item-icon-size: 4rem;
    --item-icon-w: 60px;
    --item-icon-wi: 52px;

    margin-bottom: 35px;
    .grid(1fr,repeat(2,1fr),0);

    @media @desktop-wide {
        grid-template-columns: 1fr;
    }

    &::before {
        content: '';
        background: transparent url('../img/outils-modernes.jpg') center no-repeat;
        background-size: cover;

        @media @desktop-wide {
            min-height: 360px;  
            order: 1;
        }
    }

    .h1 {
        margin-bottom: 50px;
    }

    figure {
        img {
            max-width: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    article {
        padding: 130px 60px;

        @media @laptop {
            padding: 60px 30px;
        }
    }

    .item-icon {
        .flex(row,flex-start,flex-start);

        span {
            padding-left: 20px;
        }

        strong {
            display: block;
            color: @color-m;
            font-size: 1.8rem;

            &.h3 {
                font-size: 2.4rem;
                color: @color-s;
                margin-bottom: 15px;
            }
        }
    }
}

.items-outils-modernes {
    .grid-ar(auto,repeat(2,1fr),40px);

    @media @mobile {
        grid-template-columns: 1fr;
    }
}

.ancrage-local {
    --social-color: @color2;

    padding-bottom: 100px;
    justify-items: center;
    .grid(1fr,repeat(2,1fr),0);

    @media @laptop {
        grid-template-columns: 1fr;
    }

    article {
        justify-self: flex-start;
        padding-top: 130px;
        padding-left: 40px;

        @media @desktop {
            padding-left: 0;
        }

        @media @laptop {
            padding-top: 0;
            margin-bottom: 40px;
        }
    }

    img {
        max-width: 100%;
    }

    p {
        margin-bottom: 30px;
    }

    .gcols {
        grid-gap: 120px;

        @media @desktop-wide {
            grid-gap: 60px;
        }

        @media @tablet {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
    }   

    .h3 {
        color: @color-s;
        min-height: 30px;
        margin-bottom: 30px;
        .flex();

        i {
            color: @color2;
            font-size: 3rem;
            margin-right: 10px;
        }
    }
}