//////// FORMS  ///////
input:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset;
}

:root {
    --form-color: #fff;
    --form-color-hover: @color2;
    --form-border-color: @color;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="tel"],
input[type="url"],
textarea {
    appearance: none;
    outline: 0 none;
    color: @fontcolor;
    padding: 0 15px;
    height: @height-item;
    font-size: 1.6rem;
    font-family: @font;
    border: 2px solid var(--form-border-color);
    margin-bottom: 20px;
    background-color: var(--form-color);
    .rounded(@rounded-item);
    .placeholder;

    &:focus {
        outline: 0 none !important;
        border-color: var(--form-color-hover);
        color: var(--form-color-hover);
        background-color: #fff;
    }
}

input[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        appearance: none;
    }
}

input[type="number"] {
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
    }
}

input[type="submit"] {
    font-family: @font;
    appearance: none;
    cursor: pointer;
}

button {
    font-family: @font;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    .rounded(0);
}

textarea {
    resize: vertical;
    padding: 20px 10px;
    min-height: 250px;
}

label,
.label {
    display: block;
    text-align: left;
    margin-bottom: 10px;

    em {
        display: block;
        font-style: italic;
        font-size: 0.9em;
    }
}

progress[value] {
    flex-grow: 1;
    appearance: none;
    max-width: 600px;
    width: 100%;
    height: 20px;
    overflow: hidden;
    background-color: @color;
    border: 2px solid @color2;
    .rounded(10px);

    &::-webkit-progress-bar {
        background-color: @color;
        border: 2px solid var(--form-color-hover);
        .rounded(10px);
    }

    &::-ms-fill {
        background-color: var(--form-color-hover);
    }

    &::-moz-progress-bar {
        background-color: var(--form-color-hover);
    }

    &::-webkit-progress-value {
        background-color: var(--form-color-hover);
    }
}

////// BOUTONS CHECKBOX /////
.checkbox {
    position: relative;
    z-index: 1;

    label {
        font-family: @font;
        text-transform: none;
        display: flex;
        position: relative;
        cursor: pointer;

        &::before {
            content: '';
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: 2px solid var(--form-border-color);
            margin-right: 10px;
            padding-right: 15px;
        }

        &::after {
            .picto-font;

            content: @icon-check;
            position: absolute;
            left: 0;
            top: 0;
            color: transparent;
            font-size: 1.9rem;
            .transition;
        }
    }

    input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        border: 0 none;
        z-index: -1;
        appearance: none;
        outline: none;

        &:checked + label {
            &::before {
                border-color: var(--form-color-hover);
                outline: none;
            }

            &::after {
                color: var(--form-color-hover);
                outline: none;
            }
        }
    }
} // .checkbox

///// LISTE DE BOUTONS RADIO
.list-radio {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;

    li {
        margin-bottom: 5px;
    }

    label {
        position: relative;
        cursor: pointer;
        .flex(row,flex-start,flex-start);

        &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid var(--form-border-color);
            margin-right: 10px;
            .rounded(50%);
            .transition();
        }

        &::after {
            position: absolute;
            left: 6px;
            top: 6px;
            content: '';
            height: 8px;
            width: 8px;
            background-color: transparent;
            .rounded(50%);
            .transition();
        }
    }

    input[type="radio"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        border: 0 none;
        z-index: -1;
        appearance: none;
        .rounded(50%);

        &:checked + label {
            font-weight: 700;

            &::before {
                border-color: var(--form-color-hover);
            }

            &::after {
                background-color: var(--form-color-hover);
            }
        }
    }

    &.inline {
        .flex(row,center,flex-start);

        li {
            margin-right: 15px;
        }
    }
} // .list-radio

///// SELECT CUSTOM ////
.habillage {
    height: @height-item;
    max-width: 250px;
    margin-bottom: 20px;
    position: relative;
    background-color: var(--form-color);
    border: 2px solid var(--form-border-color);
    flex-grow: 1;
    overflow: hidden;
    .rounded(@rounded-item);

    &::after {
        .picto-font;

        position: absolute;
        right: 0;
        top: 0;
        font-size: 2.6rem;
        height: @height-item;
        width: @height-item;
        color: var(--form-color);
        z-index: 97;
        content: @icon-select;
        .flex(row,center,center);
    }

    select {
        position: absolute;
        left: 0;
        top: 0;
        appearance: none;
        background-color: transparent;
        text-overflow: '';
        border: 0 none;
        font-family: @font;
        font-size: 1.6rem;
        height: 100%;
        width: 100%;
        padding: 0 45px 0 15px;
        z-index: 98;
    }
} // .habillage

.input-file {
    width: auto;
    max-width: 300px;

    label {
        cursor: pointer;
        margin-bottom: 0;
        font-size: 1.3rem;
        font-weight: 400;
    }

    i {
        font-size: 2.5rem;
        margin-right: 5px;
        font-weight: 300;
    }

    input {
        display: none;
    }

    .file-selected {
        --form-color-hover: tint(@color2, 70%);

        font-weight: 700;
        font-size: 1.5rem;
        text-align: left;
        padding: 0;
        margin-bottom: 0;
        margin-top: 15px;
        position: relative;

        &:not(:empty) {
            background-color: var(--form-color-hover);
            margin-bottom: 20px;
            padding: 10px;

            &::before {
                content: "Fichier sélectionné : ";
                display: block;
                font-style: italic;
                font-weight: 300;
                color: @black;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 15px;
                top: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 10px 12px;
                border-color: transparent transparent var(--form-color-hover) transparent;
            }
        }
    } // .file-selected
} // .input-file

.select-custom {
    max-width: 220px;
    width: 100%;
    margin-bottom: 20px;
    min-height: @height-item;
    .flex(column,flex-start,flex-start);

    select {
        display: none;
        opacity: 0;
    }

    .dropdown {
        position: relative;
        width: 100%;
        .flex(column,flex-start,flex-start);

        &.open {
            .list-select {
                opacity: 1;
                visibility: visible;
            }

            span {
                &::before {
                    transform: rotate(180deg);
                }
            }
        }

        span {
            max-height: @height-item;
            height: @height-item;
            width: 100%;
            max-width: 100%;
            background-color: #fff;
            flex-grow: 1;
            overflow: hidden;
            position: relative;
            padding: 10px 20px;
            white-space: nowrap;
            .flex(row,center,flex-start);
            .rounded(@rounded-item);

            &::before {
                content: @icon-select;
                position: absolute;
                right: 0;
                color: var(--form-color);
                width: 40px;
                height: 100%;
                background-color: #fff;
                .transition;
                .picto-font;
                .flex(row,center,center);
            }
        }

        .list-select {
            margin: 0;
            padding: 0;
            list-style: none;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: calc(@height-item + 5px);
            left: 0;
            z-index: 9999;
            right: 0;
            overflow: hidden auto;
            border-radius: 5px;
            background-color: #fff;
            .transition;

            li {
                padding: 10px 20px;
                cursor: pointer;
                .flex(row,center,flex-start);

                &:hover {
                    background-color: var(--form-color-hover);

                    a {
                        color: #fff;
                    }
                }

                a {
                    color: @fontcolor;
                    width: 100%;
                }
            }
        }
    }
} // select custom

.cp {
    max-width: 150px;
}

.full {
    width: 100%;
}

.cp-ville {
    .grid(1fr, 150px 1fr,20px);
}
