/////// GENERAL /////
:root {
    --social-color: @color;
}

html {
    font-size: 62.5%;
}

body {
    background-color: #fff;
    font-size: 1.7rem;
    line-height: 1.4;
    font-family: @font;
    color: @fontcolor;
    min-height: 100vh;
    .flex(column, stretch, space-between);

    @media @laptop {
        padding-top: 60px;
    }

    .main-content {
        flex-grow: 1;
    }
}

.logo {
    img {
        max-width: 400px;
    }
}

.author {
    padding-top: 30px;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        width: 90px;
        height: 1px;
        background-color: @color2-s;
        margin-right: 30px;
    }
}

// TOOLS
.debug {
    display: block;
    padding: 20px;
    margin: 20px 20px 40px 80px;
    color: #eee;
    background-color: #333;
}

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

a {
    color: @color;
    text-decoration: none;
    .transition;

    @media print {
        text-decoration: underline;
    }
}

strong,
b {
    font-weight: 700;
}

abbr {
    border-bottom: 1px dotted #555;
}

sup {
    vertical-align: super;
    font-size: 0.8em;
}

.blur {
    opacity: 0.4;
}

.wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 1680px;
    padding: 0 10px;
    position: relative;

    @media @desktop-wide {
        max-width: 1200px;
    }

    @media @desktop {
        max-width: 1000px;
    }

    @media @laptop {
        max-width: 768px;
    }

    @media @tablet {
        max-width: 640px;
    }

    @media @mobile {
        max-width: none;
    }
}

.main-content {
    // gabarit principal
} // .main-content

/// LAYOUT ///
.cols {
    display: flex;
    justify-content: space-between;
}

.gcols {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 20px;

    &.col2 {
        grid-template-columns: repeat(2, 1fr);
    }

    &.col3 {
        grid-template-columns: repeat(3, 1fr);
    }
}

// SOCIAL NETWORKS
.social {
    .flex(row,center,flex-start);

    a {
        font-size: 3.6rem;
        margin-right: 10px;
        color: var(--social-color);
        .flex-center();

        /* stylelint-disable */
        each(@socials, {
            &.@{key} {
                &:hover {
                    color: @value;
                }
            }
        });
    }
} // .social

// TARTE AU CITRON
span.tarteaucitron-magic-block {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8000;
    max-width: none !important;
}