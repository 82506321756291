/////// FOOTER //////

footer {
    background-color: @color-m;
    color: #fff;
    min-height: 50px;
    font-size: 1.4rem;
    .flex(row,center,flex-start);

    a {
        color: #fff;
        opacity: 1;

        &:hover {
            opacity: 0.5;
        }
    }

    @media print {
        display: none;
    }
} // footer
