/////// HEADER //////
:root {
    --logo-width: 350px;

    @media @desktop {
        --logo-width: 250px;
    }
}

header {
    --social-color: #fff;

    background-color: @color2;
    color: #fff;
    font-size: 1.6rem;  
    padding: 0 30px;
    min-height: 60px;
    .flex();

    @media @laptop {
        padding-right: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 600;
    }

    @media print {
        display: none;
    }

    p {
        span {
            margin: 0 15px;
        }

        @media @desktop-wide {
            .flex(column,flex-start,flex-start);
        }

        @media @laptop {
            display: none;
        }
    }

    .logo {
        img {
            display: block;
            min-width: var(--logo-width);
        }
    }
} // header

.social-header {
    .flex(row,center,flex-end);

    @media @laptop {
        display: none;
    }

    p {
        position: relative;
        top: 3px;
        font-size: 2rem;
        line-height: 1; 

        @media @desktop {
            display: none;
        }
    }

    .social {
        margin-left: 15px;
    }
}

.toggle-nav {
    display: none;
    cursor: pointer;
    position: relative;
    width: 100px;
    height: 55px;
    background-color: transparent;

    @media @laptop {
        display: block;
    }

    &.menu-open {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9999;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10px;
        display: block;
        width: 20px;
        height: 3px;
        background-color: #fff;
        .rotate(0);

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 20px;
            height: 3px;
            background-color: #fff;
            transform: translateY(-6px);
            .transition;
        }

        &::after {
            transform: translateY(0);
            top: 6px;
        }

        .menu-open& {
            background-color: transparent;

            &::before {
                background-color: #fff;
                .rotate(-45deg);
            }

            &::after {
                background-color: #fff;
                .rotate(45deg);
                .origin(18px -2px);
            }
        } // .menu-open
    }
} // .toggle-nav

.main-nav {
    display: none;
    flex-grow: 1;
    .transition;

    @media @laptop {
        right: -100%;
        position: fixed;
        overflow: scroll;
        z-index: 9998;
        width: 100%;
        height: 100vh;
        top: 0;
        background-color: @color;
        .transition;
        .flex(column, center, center);
    }

    &.showing-nav {
        display: flex;
        right: 0;
    }
}

.menu {
    margin-bottom: 30px;
    .flex(column,center,center);

    a {
        color: #fff;
        font-size: 1.8rem;
        margin-bottom: 20px;
    }
}